// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artur-voskanyan-js": () => import("./../../../src/pages/artur-voskanyan.js" /* webpackChunkName: "component---src-pages-artur-voskanyan-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marina-bludyan-js": () => import("./../../../src/pages/marina-bludyan.js" /* webpackChunkName: "component---src-pages-marina-bludyan-js" */),
  "component---src-pages-news-ert-sochi-js": () => import("./../../../src/pages/news/ert-sochi.js" /* webpackChunkName: "component---src-pages-news-ert-sochi-js" */),
  "component---src-pages-news-fw-russia-poker-championship-js": () => import("./../../../src/pages/news/FW-Russia-poker-championship.js" /* webpackChunkName: "component---src-pages-news-fw-russia-poker-championship-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-silnye-figuri-v-gelendgike-js": () => import("./../../../src/pages/news/silnye-figuri-v-gelendgike.js" /* webpackChunkName: "component---src-pages-news-silnye-figuri-v-gelendgike-js" */),
  "component---src-pages-turnirnyj-poker-js": () => import("./../../../src/pages/turnirnyj-poker.js" /* webpackChunkName: "component---src-pages-turnirnyj-poker-js" */)
}

